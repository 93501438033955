<template>
  <div class="title"></div>
</template>

<script>
import { getCasLoginUrl } from "@/utils/casUrl";
export default {
  name: "HelloWorld",
  data() {
    return {};
  },
  methods: {
    casLogin() {
      let casURl = getCasLoginUrl();
      console.log(casURl);
      window.location.href = casURl;
    }
  },
  created() {
    this.casLogin();
  }
};
</script>
<style></style>
